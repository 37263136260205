import { BrowserRouter as Router,Route, Switch } from 'react-router-dom';
import { useState } from 'react';
import HomeTwo from './pages/homes/HomeTwo';
import Shop from './pages/store/Shop';
import Contact from './pages/contact/Contact';
import Community from './pages/community/Community';
import Overview from './pages/overview/Overview';
import AboutUs from './pages/aboutpage/aboutuspage/AboutUs';
import Upcoming from './pages/aboutpage/upcoming/Upcoming';
import GameSingle from './pages/aboutpage/singlegame/GameSingle';
import Blogs from './pages/blogs/Blogs';
import BlogDetails from './pages/blogdetails/BlogDetails';
import ScrollToTopRoute from './components/scrolltotop/ScrollToTopRoute';
import LoginDetail from './pages/Login/LoginDetail';
import RegisterDetail from './pages/Register/RegisterDetail';
import Game from './pages/game/Game';
import PrivateRoute from './components/PrivateRoute';
import FpasswordDetail from './pages/ForgotPassword/FpasswordDetail';
import ProfilesDetail from './pages/Profiles/ProfilesDetail';
import FaQDetail from './pages/FAQ/FaQDetail';
import AboutDetail from './pages/AboutUs/AboutDetail';
import PrivacyDetail from './pages/PrivacyPolicy/PrivacyDetail';
import RefundDetail from './pages/Refund/RefundDetail';
import TnC from './pages/Terms & Condition/TnC';
import TnCDetail from './pages/Terms & Condition/TnCDetail';
import LoginREdetails from './pages/Login/LoginREdetails';
import TopUpDetails from './pages/store/TopUpDetails';
import PaymentsDetail from './pages/payments/PaymentsDetail';
import Confirmation from './pages/confirmation/Confirmation';
import ConfirmationDetails from './pages/confirmation/ConfirmationDetails';
import ProductDetails from './pages/TopupTemplate/ProductDetails';
import Thistory from './pages/transactionHistory/Thistory';
import WhatsupBtn from './components/WhatsupBtn';
import RegisterREDetails from './pages/Register/RegisterREDetails';
import SpageDetails from './pages/successpage/SpageDetails';
import Maintenance from './pages/Maintenance/Maintenance';
import TopUpWallet from './pages/store/TopUpWallet';
import TopUpWalletDetails from './pages/store/TopUpWalletDetails';
import ConfirmationTopUp from './pages/confirmation/ConfirmationTopUp';
import TopupGame from './pages/store/TopupGame';
import TagManager from 'react-gtm-module';
import InstagramBtn from './components/InstagramBtn';
import { ToastContainer } from 'react-toastify';
import Spage from './pages/successpage/Spage';
import SpageTopupDetails from './pages/successpage/SpageTopup';

function App() {
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [isGlobalMaintenance, setIsGlobalMaintenance] = useState(false);

  const tagManagerArgs = {
    gtmId: 'GTM-5DG5KWXF'
  };
  
  TagManager.initialize(tagManagerArgs);
  
  return (
    <div className="App">
      <Router>
        <Switch>
          {isGlobalMaintenance ? (
            <>
              <Route path="*" component={Maintenance} />
            </>
          ) : (
            <>
              <div style={{position:'relative'}}>
                <ScrollToTopRoute exact={true} path="/">
                  <HomeTwo />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/shop">
                  <Shop />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/contact">
                  <Contact />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/community">
                  <Community />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/overview">
                  <Overview />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/about-us">
                  <AboutUs />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/upcoming-games">
                  <Upcoming />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/game-single">
                  <GameSingle />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/blogs">
                  <Blogs />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/blog-details">
                  <BlogDetails />
                </ScrollToTopRoute>
                <PrivateRoute exact={true} path="/shop/:id" component={Game} />
                <ScrollToTopRoute exact={true} path="/login">
                  <LoginDetail />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/forgot-password">
                  <FpasswordDetail />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/register">
                  <RegisterDetail />
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/profile">
                  <ProfilesDetail/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/faq">
                  <FaQDetail/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/about-nero">
                  <AboutDetail/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/privacy-policy">
                  <PrivacyDetail/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/refund-policy">
                  <RefundDetail/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/terms-and-condition">
                  <TnCDetail/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/reseller-login">
                  <LoginREdetails/>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/top-up" component={() => (isMaintenance ? <Maintenance /> :  <TopUpDetails />)}>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/product/:gameName" component={() => (isMaintenance ? <Maintenance /> : <ProductDetails/>)}>
                </ScrollToTopRoute>
                <ScrollToTopRoute exact={true} path="/register-reseller">
                  <RegisterREDetails/>
                </ScrollToTopRoute>
                <PrivateRoute exact={true} path="/payment/:userID" component={PaymentsDetail} />
                {/* <PrivateRoute
                  exact={true}
                  path="/top-up"
                  component={() => (isMaintenance ? <Maintenance /> :  <TopUpDetails />)}
                /> */}
                <PrivateRoute exact={true} path="/confirmation/:merchantRef" component={ConfirmationDetails} />
                <PrivateRoute exact={true} path="/confirmation-topup/:merchantRef" component={ConfirmationTopUp} />
                <PrivateRoute exact={true} path="/top-up-game-coin" component={TopupGame} />
                <PrivateRoute exact={true} path='/top-up-coin' component={TopUpWallet}/>
                {/* <PrivateRoute exact={true} path="/product/:gameName" component={ProductDetails}/> */}
                <PrivateRoute exact={true} path="/transaction-history/:userID" component={Thistory}/>
                <PrivateRoute exact={true} path="/success-payment/:transaction_id" component={SpageDetails}/>
                <PrivateRoute exact={true} path="/success-topup/:transaction_id" component={SpageTopupDetails}/>
                <WhatsupBtn/>
                <InstagramBtn/>

                <ToastContainer />
              </div>
            </>
          )}
        </Switch>
      </Router>
    </div>
  );
}

export default App;
