import React, { useState } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { Button } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import "bootstrap/dist/css/bootstrap.min.css";
import API from "../../api";
import { toast, ToastContainer } from "react-toastify";
import NeroLoader from "../../components/loaders/NeroLoader";
import { arrayFund } from "../../data/arrayFund";

const getCurrentDateTime = () => {
  return new Date().toISOString();
};

const Payment = () => {
  const { userID } = useParams();
  const location = useLocation();
  const history = useHistory();
  const userData = location.state;
  const [paymentMethodDetails, setPaymentMethodDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  if (!userData) {
    return <p>Invalid access</p>;
  }

  const handleBack = () => {
    history.push("/top-up", userData);
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    console.log("Form submitted with values:", values);
  
    const selectedParent = arrayFund.find(
      (method) =>
        method.child.some((child) => child.code === values.paymentMethod) ||
        method.name.toLowerCase() === values.paymentMethod.toLowerCase()
    );
  
    const parentMethodName = selectedParent ? selectedParent.payment_method : values.paymentMethod;
  
    // Determine the code to use
    let selectedCode = "";
  
    if (selectedParent && selectedParent.child.length > 0) {
      // If there are child elements, find the selected one
      const selectedChild = selectedParent.child.find((child) => child.code === values.paymentMethod);
      selectedCode = selectedChild ? selectedChild.code : "";
    } else {
      // If no child elements, use the parent's name as the code (e.g., "qris")
      selectedCode = parentMethodName;
    }
  
    const user_id_nero = localStorage.getItem('user_id_nero');
  
    // Adjusted submission data to match expected backend payload
    const submissionData = {
      amount: userData.amount,  // Amount to top up
      payment_method: parentMethodName,  // e.g., "e-wallet"
      channel_code: selectedCode,  // e.g., "ovo"
      phone_number: userData.phoneNumber,
      ref_id: userData.ref_id || "",
    };
  
    console.log("Submitting data to API:", submissionData);
  
    try {
      const response = await API.post("payment-qoin", submissionData);
      console.log("API response:", response);
  
      if (response.status === 201) {
        let details;
        if (parentMethodName === "virtual account") {
          details = response.data.virtualAccount;
        } else if (parentMethodName === "qris") {
          details = response.data.qris;
        } else if (parentMethodName === "e-wallet") {
          details = response.data.eWallet;
        } else {
          details = response.data;
        }
        
        setPaymentMethodDetails(details);
        console.log("Redirecting to confirmation...");
        console.log("MerchantRef:", response.data.merchantRef);
        history.push({
          pathname: `/confirmation-topup/${response?.data?.payment_request_id}`, // Use response.data.data.id
          state: {
            userData: {
              ...submissionData,
              status: response.status,
              transaction_id: response.data.id, // Use response.data.data.id
            },
            paymentDetails: details,
          },
        });
      }
       else {
        toast.error(response.message || "An error occurred", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error during payment processing", error);
      toast.error("An error occurred during payment processing", {
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };
  
  

  return (
    <div className="payment-page">
      <div className="payment-wrapper">
        {loading && (<NeroLoader />)}
        <div className={`content ${loading ? "loading" : ""} text-black text-lg`}>
          <div className="line-1"></div>
          <div className="amount-container">
            <span>{userData.amount} Coin</span>
            <span>IDR {userData.amount}</span>
          </div>
          <div className="line-1"></div>
          <div className="amount-container text-lg md:text-xl lg:text-2xl font-semibold">
            <span>Total</span>
            <span>IDR {userData.amount}</span>
          </div>
          <div className="line-1" style={{ marginBottom: 10 }}></div>
          <div className="user-info" style={{ marginBottom: 10 }}>
            <div className="user-wrapper">
              <div className="title-form w-100 b-1">
                <div
                  className="d-flex w-100 gap-15"
                  style={{ gap: 60, padding: "5px 0px" }}
                >
                  <p>Username</p>
                  <p style={{ color: "black" }}>{userData.username}</p>
                </div>
                <div className="line-1"></div>
                <div
                  className="d-flex w-100 gap-25"
                  style={{ padding: "5px 0px" }}
                >
                  <p>Phone Number</p>
                  <p style={{ color: "black" }}>{userData.phoneNumber}</p>
                </div>
                <div className="line-1"></div>
                <div
                  className="d-flex w-100"
                  style={{ gap: 70, padding: "5px 0px" }}
                >
                  <p>Game ID</p>
                  <p style={{ color: "black" }}>{userData.game}</p>
                </div>
                <div className="line-1"></div>
                <div
                  className="d-flex w-100"
                  style={{ gap: 75, padding: "5px 0px" }}
                >
                  <p>Amount</p>
                  <p style={{ color: "black" }}>{userData.amount}</p>
                </div>
              </div>
            </div>
          </div>

          <h4 style={{ color: "black" }}>Select Payment Method</h4>
          <Formik
            initialValues={{
              paymentMethod: "",
              cardNumber: "",
              expiryDate: "",
              cvv: "",
            }}
            onSubmit={handleSubmit}
          >
            {({ handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Accordion defaultActiveKey="0">
                  {arrayFund.map((method, idx) => (
                    <Accordion.Item eventKey={idx.toString()} key={idx}>
                      <Accordion.Header>
                        <div className="d-flex justify-content-between w-100">
                          <div className="d-flex flex-column">
                            <span>{method.name}</span>
                          </div>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        {method.child.length > 0 ? (
                          <ul className="grid-template">
                            {method.child.map((child, cidx) => (
                              <li
                                key={cidx}
                                style={{ listStyle: "none", marginBottom: 10 }}
                              >
                                <label
                                  htmlFor={child.code}
                                  style={{ marginLeft: 8 }}
                                  className="radio-card"
                                >
                                  <Field
                                    type="radio"
                                    name="paymentMethod"
                                    value={child.code}
                                    id={child.code}
                                  />
                                  <div className="card-content-wrapper height-170">
                                    <span className="check-icon"></span>
                                    <div className="card-content">
                                      <img
                                        src={child.image}
                                        alt={child.name}
                                        style={{
                                          width: 80,
                                          height: 80,
                                          marginRight: 10,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </label>
                              </li>
                            ))}
                          </ul>
                        ) : method.name === "Qris" ? (
                          <label
                            htmlFor={method.name}
                            style={{ marginLeft: 8 }}
                            className="radio-card"
                          >
                            <Field
                              type="radio"
                              name="paymentMethod"
                              value={method.name}
                              id={method.name}
                              className="form-check-input"
                            />
                            <div className="card-content-wrapper height-170">
                              <span className="check-icon"></span>
                              <div className="card-content">
                                <img
                                  src={method.image}
                                  alt="QRIS"
                                  style={{ width: 100, height: 100 }}
                                />
                              </div>
                            </div>
                          </label>
                        ) : null}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
                <div
                  style={{
                    justifyContent: "center",
                    marginTop: "20px",
                    display: "flex",
                    gap: 20,
                  }}
                >
                  <Button className="black" type="button" onClick={handleBack}>
                    Back
                  </Button>
                  <Button className="black" type="submit">
                    Pay Now
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Payment;
