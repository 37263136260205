export const arrayFund = [
    {
      name: "Virtual Account",
      payment_method: "VA",
      is_active: 1,
      child: [
        {
          code: "BCA",
          name: "BCA",
          is_active: 1,
          image:
            "https://storage.googleapis.com/nero-assets/payment-method/bca-logo.svg",
        },
        {
          code: "MANDIRI",
          name: "Mandiri",
          is_active: 1,
          image:
            "https://storage.googleapis.com/nero-assets/payment-method/mandiri-logo.svg",
        },
        {
          code: "BRI",
          name: "BRI",
          is_active: 1,
          image:
            "https://storage.googleapis.com/nero-assets/payment-method/bri-logo.svg",
        },
        {
          code: "BNI",
          name: "BNI",
          is_active: 1,
          image:
            "https://storage.googleapis.com/nero-assets/payment-method/bni-logo.svg",
        },
        {
          code: "PERMATA",
          name: "Permata",
          is_active: 1,
          image:
            "https://storage.googleapis.com/nero-assets/payment-method/permata-logo.svg",
        },
        {
          code: "CIMB",
          name: "CIMB",
          is_active: 1,
          image:
            "https://storage.googleapis.com/nero-assets/payment-method/cimb-logo.svg",
        },
      ],
    },
    {
      name: "Qris",
      payment_method: "QR",
      is_active: 1,
      child: [],
      image:
        "https://storage.googleapis.com/nero-assets/payment-method/qris-logo.svg",
    },
    {
      name: "E-Wallet",
      payment_method: "EWallet",
      is_active: 1,
      child: [
        // {
        //   code: "OVO",
        //   name: "OVO",
        //   is_active: 1,
        //   image:
        //     "https://storage.googleapis.com/nero-assets/payment-method/ovo-logo.svg",
        // },
        {
          code: "DANA",
          name: "DANA",
          is_active: 1,
          image:
            "https://storage.googleapis.com/nero-assets/payment-method/dana-logo.svg",
        },
        {
          code: "SHOPEEPAY",
          name: "SHOPEEPAY",
          is_active: 1,
          image:
            "https://storage.googleapis.com/nero-assets/payment-method/shopeepay-logo.svg",
        },
      ],
    },
  ];