import { useRef, useState } from "react";
import { isAuthenticated } from "../../utils/auth";
import { useHistory } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { getImageByDiamondCount } from "../../utils/getImageByDiamondCount";
import { addMargin } from "../../utils/addMarginFee";
import { formatCurrency } from "../../utils/formatCurrency";
import CIcon from "@coreui/icons-react";
import { cilLockLocked } from "@coreui/icons";
import { Accordion, Button } from "react-bootstrap";
import { arrayFund } from "../../data/arrayFund";

export const ProductForm = ({ game, denominations, gameConfig, onSubmit }) => {
    const [selectedPrice, setSelectedPrice] = useState(0);
    const isLoggedIn = isAuthenticated();
    const history = useHistory(); // For navigation
    const detailAkunRef = useRef(null);
  
    const userIdNero = localStorage.getItem("user_id_nero"); // Assuming the user ID is stored in localStorage
  
    const handleLoginRedirect = () => {
      // Save the current URL so that the user can return after logging in
      const currentPath = window.location.pathname;
      localStorage.setItem('redirectAfterLogin', currentPath); // Save to localStorage
      history.push('/login'); // Redirect to the login page
    };
  
    const handleDenomSelect = (price) => {
      setSelectedPrice(price);
      if (detailAkunRef.current) {
        const elementPosition = detailAkunRef.current.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - 100; // Add 100px offset from the top
  
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    };
  
    return (
      <Formik
        initialValues={{
          selectedDenom: "",
          userID: "",
          zoneID: "",
          phoneNumber: "",
          paymentMethod: "",
          ref_id: "",
          username: ""
        }}
        validationSchema={gameConfig.schema}
        onSubmit={(values) => onSubmit({ ...values, user_id_nero: userIdNero })}
      >
        {({ handleSubmit, values, isValid, dirty }) => (
          <Form onSubmit={handleSubmit}>
            <div className="grid-wrapper grid-col-auto">
              {denominations.map((item, index) => {
                const diamondCount = item.package;
                const imageUrl = getImageByDiamondCount(game.name, diamondCount);
                const priceWithMargin = addMargin(item.price);
  
                return (
                  <label
                    key={item.id}
                    htmlFor={`radio-card-${index}`}
                    className="radio-card"
                  >
                    <Field
                      type="radio"
                      name="selectedDenom"
                      id={`radio-card-${index}`}
                      value={item.package}
                      onClick={() => handleDenomSelect(priceWithMargin)}
                    />
                    <div className="card-content-wrapper">
                      <span className="check-icon"></span>
                      <div className="card-content">
                        <img src={imageUrl} alt={item.package} />
                        <h4>{item.package}</h4>
                        <h5>Price: {formatCurrency(priceWithMargin)}</h5>
                      </div>
                    </div>
                  </label>
                );
              })}
            </div>
            <ErrorMessage
              name="selectedDenom"
              component="div"
              className="text-danger danger"
            />
  
            <div style={{ position: 'relative' }}>
              {!isLoggedIn && (
                <div className="div-blocking">
                  <CIcon style={{ width: '2rem', height: '2rem' }} icon={cilLockLocked} />
                  <h2 style={{ color: 'white', marginTop: '20px' }}>
                    Silahkan login terlebih dahulu untuk melakukan transaksi
                  </h2>
                  <button
                    style={{
                      padding: '10px 20px',
                      backgroundColor: '#ffc700',
                      border: 'none',
                      borderRadius: '8px',
                      color: 'black',
                      fontWeight: 'bold',
                      cursor: 'pointer',
                      marginTop: '10px'
                    }}
                    onClick={handleLoginRedirect}
                  >
                    Go to Login
                  </button>
                </div>
              )}

              <div
                ref={detailAkunRef}
                className={`form-user-id ${!isLoggedIn ? 'blocked' : ''}`}>
                <div className="account-detail">
                  <div className="number-symbol border-none">2</div>
                  <h2 className="text-black">Masukan Detail Akun</h2>
                </div>
                <div className="form-container-product">
                  {gameConfig.formFields
                    ?.filter(
                      (field) =>
                        field.name !== "phoneNumber" && field.name !== "ref_id"
                    )
                    .map((field) => (
                      <div
                        key={field.name}
                        className={`${field.name} flex flex-col items-center justify-center gap-1`}
                      >
                        <label className={`${field.name} text-black`}>
                          {field.label}
                          {field.type === "select" ? (
                            <Field as="select" name="zoneID" className="Select">
                              <option value="">Select Server ID</option>
                              {field.options.map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </Field>
                          ) : (
                            <Field
                              type={field.type}
                              name={field.name}
                              className="border-1 border-gray-700"
                            />
                          )}
                          <ErrorMessage
                            name={field.name}
                            component="div"
                            className={`${field.name}-text-danger danger pb-10 text-xs`}
                          />
                        </label>
                      </div>
                    ))}
                </div>
              </div>
  
              <div className="form-user-id">
                <div className="account-detail">
                  <div className="number-symbol border-none">3</div>
                  <h2 className="text-black">Detail Informasi Tambahan</h2>
                </div>
                <div className="form-container-product">
                  <div className="phoneNumber">
                    <label className="phoneNumber text-black">
                      Phone Number
                      <Field
                        type="text"
                        name="phoneNumber"
                        className="border-1 border-gray-700"
                      />
                    </label>
                    <ErrorMessage
                      name="phoneNumber"
                      component="div"
                      className="phoneNumber-text-danger danger"
                    />
                  </div>
                  <div className="ref_id">
                    <label className="ref_id text-black">
                      Ref ID (Optional)
                      <Field
                        type="text"
                        name="ref_id"
                        className="border-1 border-gray-700"
                      />
                    </label>
                    <ErrorMessage
                      name="ref_id"
                      component="div"
                      className="ref_id-text-danger danger"
                    />
                  </div>
                </div>
              </div>
  
              <h2
                style={{ marginTop: 20, color: "white", fontWeight: "bolder" }}
                className="text-gray-900 bg-white p-4 mb-2 mt-5 rounded-lg font-medium"
              >
                Pilih Metode Pembayaran
              </h2>
  
              {/* Accordion for payment methods */}
              <Accordion defaultActiveKey="0">
                {arrayFund.map((method, idx) => {
                  const isVirtualAccountDisabled = selectedPrice < 10000;
                  const isQrisDisabled = selectedPrice < 5000;
                  const isEWalletDisabled = selectedPrice < 1000;
  
                  const getMethodMessage = (methodName) => {
                    switch (methodName) {
                      case "Virtual Account":
                        return isVirtualAccountDisabled
                          ? "This method is disabled for amounts less than 10,000"
                          : null;
                      case "Qris":
                        return isQrisDisabled
                          ? "This method is disabled for amounts less than 5,000"
                          : null;
                      case "E-Wallet":
                        return isEWalletDisabled
                          ? "This method is disabled for amounts less than 1,000"
                          : null;
                      default:
                        return null;
                    }
                  };
  
                  return (
                    <Accordion.Item eventKey={idx.toString()} key={idx}>
                      <Accordion.Header>{method.name}</Accordion.Header>
                      <Accordion.Body>
                        {getMethodMessage(method.name) ? (
                          <p style={{ color: "red" }}>
                            {getMethodMessage(method.name)}
                          </p>
                        ) : method?.child?.length > 0 ? (
                          <ul className="grid-template">
                            {method?.child?.map((child, cidx) => (
                              <li
                                key={cidx}
                                style={{ listStyle: "none", marginBottom: 10 }}
                              >
                                <label
                                  htmlFor={child.code}
                                  style={{ marginLeft: 8 }}
                                  className="radio-card"
                                >
                                  <Field
                                    type="radio"
                                    name="paymentMethod"
                                    value={child.code}
                                    id={child.code}
                                  />
                                  <div className="card-content-wrapper height-170">
                                    <span className="check-icon"></span>
                                    <div className="card-content">
                                      <img
                                        src={child.image}
                                        alt={child.name}
                                        style={{
                                          width: 80,
                                          height: 80,
                                          marginRight: 10,
                                        }}
                                        loading="lazy"
                                      />
                                    </div>
                                  </div>
                                </label>
                              </li>
                            ))}
                          </ul>
                        ) : method.name === "Qris" ? (
                          <label
                            htmlFor={method.name}
                            style={{ marginLeft: 8 }}
                            className="radio-card"
                          >
                            <Field
                              type="radio"
                              name="paymentMethod"
                              value={method.name}
                              id={method.name}
                              className="form-check-input"
                            />
                            <div className="card-content-wrapper height-170">
                              <span className="check-icon"></span>
                              <div className="card-content">
                                <img
                                  src={method.image}
                                  alt="QRIS"
                                  style={{ width: 100, height: 100 }}
                                />
                              </div>
                            </div>
                          </label>
                        ) : null}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
  
              <ErrorMessage
                name="paymentMethod"
                component="div"
                className="text-danger danger"
              />
            </div>
  
            <div
              className="button-container"
              style={{
                justifyContent: "center",
                marginTop: "20px",
                display: "flex",
                gap: 20,
              }}
            >
              <Button
                id="submit-btn-voucher"
                className="black"
                type="submit"
                disabled={!(isValid && dirty) || !isLoggedIn} // Disable button if user is not logged in
              >
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };