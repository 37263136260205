import React from 'react'
import Spage from './Spage'

function SpageTopupDetails() {
  return (
    <div>
        <Spage isTopup />
    </div>
  )
}

export default SpageTopupDetails