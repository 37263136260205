import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import CIcon from "@coreui/icons-react";
import { cilAvTimer } from "@coreui/icons";
import dayjs from "dayjs";
import API from "../../api"; // Ensure this path is correct
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaymentDetail from "./PaymentDetail";
import { formatCurrency } from "../../utils/formatCurrency";

function ConfirmationTopUp() {
  const history = useHistory();
  const params = useParams()

  const [paymentData, setPaymentData] = useState(null);

  // Redirect popup if Ewallet / CreditCard
  useEffect(() => {
    if(paymentData) {
      if ((paymentData?.payment_method === "EWallet" || paymentData?.payment_method === "CreditCard") && paymentData?.payment_status === "Pending") {
        const popupWidth = 600;
        const popupHeight = 600;
        const left = (window.innerWidth / 2) - (popupWidth / 2);
        const top = (window.innerHeight / 2) - (popupHeight / 2);

        const redirectUrlPayment = paymentData?.additional_info?.actions?.find(val => val?.type === "REDIRECT_CUSTOMER")?.value

        if(redirectUrlPayment) {
          window.open( redirectUrlPayment, 'paymentPopup', `width=${popupWidth},height=${popupHeight},top=${top},left=${left}`);
        }
      }
    }
  }, [paymentData]);

  const dataDetailPayment = useCallback(async () => {
    if (params?.merchantRef) {
      try {
        const response = await API.post('payment-detail-qoin', { id: params?.merchantRef });

        if(response?.status !== 200) return history.replace('/top-up');

        if(response?.data?.status?.toLowerCase() === "success") return history.replace(`/success-topup/${params?.merchantRef}`)
        
        if(response?.data?.status?.toLowerCase() === "pending") toast.info('Payment not set')

        setPaymentData(response?.data);
      } catch (err) {
        toast.error('error', err);
      }
    }
  }, [params?.merchantRef]);
  
  useEffect(() => {
    dataDetailPayment();
  }, [dataDetailPayment]);

  const timeExpires = paymentData?.additional_info?.channel_properties?.expires_at
  const timeExpiresFormat = timeExpires ? dayjs(timeExpires)?.format('YYYY-MM-DD HH:mm:ss') : null

  return (
    <div className="confirmation-page">

      {/* Right Side */}
      <div className="confirmation-wrapper">
        <div className="confirmation-alert">
          <h2 style={{color:'red'}} className="text-xl md:text-2xl font-semibold">JANGAN TINGGALKAN HALAMAN INI SAMPAI PEMBAYARAN SELESAI DAN BERHASIL</h2>
        </div>

        <div className="flex flex-col items-start justify-center gap-2 pb-3">
          <h2 className="text-black text-xl">Konfirmasi Pembayaran</h2>
          <div className="confirmation-content w-full">
            <div className="payment-info">
              <h4 className="pb-5 text-gray-600">Detail Pembayaran</h4>
              <PaymentDetail dataTrx={paymentData} />
            </div>
          </div>
        </div>

        <div className="buttons flex flex-row justify-start items-center gap-3">
          <Button onClick={() => history.push("/top-up")} className="black text-xs sm:text-base">
            Kembali ke Home
          </Button>
          <Button disabled={paymentData?.status?.includes('SUCCESS')} id="btn-konfirmasi-pembayaran" onClick={dataDetailPayment} className="black text-xs sm:text-base">
            Konfirmasi Payment
          </Button>
        </div>
      </div>

      {/* Left Side */}
      <div className="user-detail text-black">
        <div className="detail-header">
          <h1 className="text-black text-xl md:text-3xl pb-4">Ringkasan Pesanan</h1>
          <span className="text-gray-600">
            <strong className="text-black">Transaction ID : </strong>
            {paymentData?.payment_id}
          </span>
        </div>
        { paymentData?.payment_status !== "SUCCESS" && timeExpiresFormat ? (
          <>
            <div className="detail-time text-gray-600 my-1">
              <CIcon icon={cilAvTimer} />
              <span style={{ marginLeft: 10 }}>
                Pay before <strong className="text-black">{timeExpiresFormat}</strong>
              </span>
            </div>
            {!paymentData?.status?.includes('SUCCESS') && dayjs().isAfter(timeExpiresFormat) && (
              <p style={{ color: "red" }}>The payment link has expired.</p>
            )}
          </>
        ): null }
        <div className={`${paymentData?.payment_status !== "SUCCESS" && timeExpiresFormat ? "mt-11" : "mt-9"}`}>
          <h4 className="text-lg md:text-xl pb-2">Informasi Pengguna</h4>
          <div className="flex flex-col items-start justify-center gap-1">
          <p>
              <strong>Status:</strong> {paymentData?.status}
            </p>
            <p>
              <strong>Phone Number:</strong> {paymentData?.phone_number}
            </p>
            <p>
              <strong>Amount:</strong> {formatCurrency(paymentData?.amount)}
            </p>
            <p>
              <strong>Ref ID:</strong> {paymentData?.ref_id ?? "-"}
            </p>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ConfirmationTopUp;
