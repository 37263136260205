/**
 * Get the image URL based on the game name and diamond count.
 * @param {string} gameName - The name of the game.
 * @param {string|number} diamondCount - The number of diamonds or special package name.
 * @returns {string} - The URL of the corresponding image.
 */
export const getImageByDiamondCount = (gameName, diamondCount) => {
    const normalizedGameName = gameName.toLowerCase();
    const count = parseInt(diamondCount, 10);
  
    switch (true) {
      case normalizedGameName.includes("pubg mobile"): {
        if (typeof diamondCount === "string") {
          if (diamondCount.toLowerCase().includes("upgrade rp"))
            return "https://storage.googleapis.com/nero-assets/pubg/pubg_rp.webp";
          if (diamondCount.toLowerCase().includes("upgrade elite rp"))
            return "https://storage.googleapis.com/nero-assets/pubg/pubg_elite_rp.webp";
        }
        if (count >= 1 && count <= 120)
          return "https://storage.googleapis.com/nero-assets/pubg/pubg_1.webp";
        if (count >= 300 && count <= 600)
          return "https://storage.googleapis.com/nero-assets/pubg/pubg_2.webp";
        if (count >= 660 && count <= 950)
          return "https://storage.googleapis.com/nero-assets/pubg/pubg_3.webp";
        if (count >= 1000 && count <= 1800)
          return "https://storage.googleapis.com/nero-assets/pubg/pubg_4.webp";
        if (count >= 2000)
          return "https://storage.googleapis.com/nero-assets/pubg/pubg_5.webp";
        break;
      }
      case normalizedGameName.includes("mobile legends"): {
        if (typeof diamondCount === "string") {
          if (diamondCount.toLowerCase().includes("weekly diamond pass"))
            return "https://storage.googleapis.com/nero-assets/mlbb/weekly-mlbb.webp";
          if (diamondCount.toLowerCase().includes("twilight pass"))
            return "https://storage.googleapis.com/nero-assets/mlbb/twilight-mlbb.webp";
        }
        if (count >= 1 && count <= 127)
          return "https://storage.googleapis.com/nero-assets/mlbb/small-diamond-mlbb.webp";
        if (count >= 128 && count <= 499)
          return "https://storage.googleapis.com/nero-assets/mlbb/medium-diamond-mlbb.webp";
        if (count >= 500 && count <= 966)
          return "https://storage.googleapis.com/nero-assets/mlbb/big-diamond-mlbb.webp";
        if (count >= 967 && count <= 2625)
          return "https://storage.googleapis.com/nero-assets/mlbb/vault-diamond-mlbb.webp";
        if (count >= 2626 && count <= 3452)
          return "https://storage.googleapis.com/nero-assets/mlbb/vault-diamond-mlbb.webp";
        if (count >= 3453 && count <= 9660)
          return "https://storage.googleapis.com/nero-assets/mlbb/truck-diamond-mlbb.webp";
        if (count >= 10050)
          return "https://storage.googleapis.com/nero-assets/mlbb/bank-diamond-mlbb.webp";
        break;
      }
      case normalizedGameName.includes("free fire"): {
        if (typeof diamondCount === "string") {
          if (diamondCount.toLowerCase().includes("member mingguan"))
            return "https://storage.googleapis.com/nero-assets/freefire/free_fire_weekly.webp";
          if (diamondCount.toLowerCase().includes("member bulanan"))
            return "https://storage.googleapis.com/nero-assets/freefire/free_fire_monthly.webp";
        }
        if (count >= 5 && count <= 80)
          return "https://storage.googleapis.com/nero-assets/freefire/free_fire_xs.webp";
        if (count >= 100 && count <= 355)
          return "https://storage.googleapis.com/nero-assets/freefire/free_fire_s.webp";
        if (count >= 425 && count <= 635)
          return "https://storage.googleapis.com/nero-assets/freefire/free_fire_m.webp";
        if (count >= 720 && count <= 1075)
          return "https://storage.googleapis.com/nero-assets/freefire/free_fire_l.webp";
        if (count >= 1080)
          return "https://storage.googleapis.com/nero-assets/freefire/free_fire_xl.webp";
        break;
      }
      case normalizedGameName.includes("blood strike"): {
        if (typeof diamondCount === "string") {
          if (diamondCount.toLowerCase().includes("strike pass (elite)"))
            return "https://storage.googleapis.com/nero-assets/bloodstrike/blood_strike_pass_elite.webp";
          if (diamondCount.toLowerCase().includes("strike pass (premium)"))
            return "https://storage.googleapis.com/nero-assets/bloodstrike/blood_strike_pass_premium.webp";
        }
        const bloodStrikeImages = {
          100: "blood_strike_xs.webp",
          300: "blood_strike_s.webp",
          500: "blood_strike_m.webp",
          1000: "blood_strike_l.webp",
          2000: "blood_strike_xl.webp",
          5000: "blood_strike_xxl.webp"
        };
        if (bloodStrikeImages[count])
          return `https://storage.googleapis.com/nero-assets/bloodstrike/${bloodStrikeImages[count]}`;
        break;
      }
      default:
        return "https://storage.googleapis.com/nero-assets/coin/stack-of-gold-coins-2.png";
    }
    return "";
  };
  