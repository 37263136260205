import { cilCopy } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { Button } from "react-bootstrap";
import { formatCurrency } from "../../utils/formatCurrency";
import { copyToClipboard } from "../../utils/copyToClipboard";
import { selectImageBank } from "../../data/imageBank";
import {QRCodeSVG} from 'qrcode.react';

export default function PaymentDetail({ dataTrx }) {
    const paymentStatus = dataTrx?.payment_status ?? dataTrx?.status
    const dataAction = dataTrx?.additional_info?.actions?.find(val => val?.type === "PRESENT_TO_CUSTOMER")?.value
    const dataActionRedirect = dataTrx?.additional_info?.actions?.find(val => val?.type === "REDIRECT_CUSTOMER")?.value
    const channelCode = dataTrx?.additional_info?.channel_code
    const bankImage = selectImageBank(channelCode)

    if (dataTrx?.payment_method?.includes("VA")) {
        return (
            <div className="method-wrapper w-full flex flex-col md:flex-row items-center justify-between gap-5">
                <div className="w-full">
                    <h5 className="text-black text-xl md:text-2xl font-semibold">Virtual Account</h5>
                    <p>
                        <strong>Bank Code:</strong> <span className="text-gray-500">{channelCode}</span>
                    </p>
                    <p>
                        <strong>VA Number:</strong> {dataAction}
                        <Button style={{ backgroundColor: 'transparent', border: 'none', marginLeft: "2px" }} onClick={() => copyToClipboard(dataAction)}>
                            <CIcon style={{ color: "black" }} icon={cilCopy} />
                        </Button>
                    </p>
                    <p>
                        <strong>View Name:</strong> {dataTrx?.additional_info?.channel_properties?.display_name}
                    </p>
                    <p>
                        <strong>Jumlah yang Harus Dibayar:</strong> {formatCurrency(dataTrx?.amount)}
                    </p>
                    <p>
                        <strong>Status pembayaran:</strong> {paymentStatus}
                    </p>
                </div>
                {bankImage && <img style={{ width: '40%' }} src={bankImage} alt={`Bank ${bankImage}`} />}
            </div>
        );
    } else if (dataTrx?.payment_method === "QR") {
        return (
            <div className="method-wrapper">
                <div>
                    <h5 className="text-black text-xl md:text-2xl font-semibold">QRIS</h5>
                    <p>
                        <strong>Jumlah yang Harus Dibayar:</strong> {formatCurrency(dataTrx?.amount)}
                    </p>
                    <p>
                        <strong>Status Pembayaran:</strong> {paymentStatus}
                    </p>
                </div>
                {dataAction ?<QRCodeSVG value={dataAction} className="my-5" /> : null}
            </div>
        );
    } else if (dataTrx?.payment_method === "EWallet" || dataTrx?.payment_method === "Credit Card") {
        return (
            <>
                <h5 className="text-black text-xl md:text-2xl font-semibold">{dataTrx?.payment_method}</h5>
                <p>
                    {/* <strong>View Name:</strong> {paymentDetails.eWallet.viewName} */}
                </p>
                <p> <strong>Channel :</strong> {channelCode}</p>

                {dataActionRedirect ?<QRCodeSVG value={dataActionRedirect} className="my-5" /> : null}

                <p><strong>Jumlah yang Harus Dibayar :</strong> {formatCurrency(dataTrx?.amount)}</p>

                <p><strong>Status Pembayaran :</strong> {paymentStatus}</p>
            </>
        );
    } else {
        return <div>No Data...</div>
    }
}