export const selectImageBank = (code) => {
    if (!code) return null;
    
    const upperCaseCode = code.toUpperCase();
    
    switch (true) {
        case upperCaseCode.includes("BRI"):
            return bankImages["BRI"];
        case upperCaseCode.includes("BCA"):
            return bankImages["BCA"];
        case upperCaseCode.includes("MANDIRI"):
            return bankImages["MANDIRI"];
        case upperCaseCode.includes("BNI"):
            return bankImages["BNI"];
        case upperCaseCode.includes("PERMATA"):
            return bankImages["PERMATA"];
        case upperCaseCode.includes("DANAMON"):
            return bankImages["DANAMON"];
        case upperCaseCode.includes("CIMB"):
            return bankImages["CIMB"];
        case upperCaseCode.includes("BSS"):
            return bankImages["BSS"];
        default:
            return null;
    }
};

export const bankImages = {
    "BCA": 'https://storage.googleapis.com/nero-assets/payment-method/bca-logo.svg',
    "MANDIRI": 'https://storage.googleapis.com/nero-assets/payment-method/mandiri-logo.svg',
    "BRI": 'https://storage.googleapis.com/nero-assets/payment-method/bri-logo.svg',
    "BNI": 'https://storage.googleapis.com/nero-assets/payment-method/bni-logo.svg',
    "PERMATA": 'https://storage.googleapis.com/nero-assets/payment-method/permata-logo.svg',
    "DANAMON": 'https://storage.googleapis.com/nero-assets/payment-method/danamon-logo.svg',
    "CIMB": 'https://storage.googleapis.com/nero-assets/payment-method/cimb-logo.svg',
    "BSS": 'https://storage.googleapis.com/nero-assets/payment-method/bss-logo.svg'
};