import React, {useCallback, useEffect, useState} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import CIcon from "@coreui/icons-react";
import { cilHome } from "@coreui/icons";
import { addMargin } from '../../utils/addMarginFee';
import API from '../../api';
import { formatCurrency } from '../../utils/formatCurrency';

function Spage({ isTopup }) {
  const history = useHistory();
  const params = useParams()

  const [paymentData, setPaymentData] = useState(null);

  const handleBackToHome = () => {
    history.push("/top-up"); // Redirect to the desired home route
  };

  const dataDetailPayment = useCallback(async () => {
      if (params?.transaction_id) {
          const response = await API.post( isTopup ? "payment-detail-qoin" : 'payment-detail', { id: params?.transaction_id });
  
          if(response?.status !== 200) return history.replace('/top-up')
          setPaymentData(response?.data);
      }
    }, [params?.transaction_id]);
    
    useEffect(() => {
      dataDetailPayment();
    }, [dataDetailPayment]);

  const username = paymentData?.name ?? localStorage.getItem('username') ?? "-"

  return (
    <div className="sPage-details">
      <div className="sPage-header">
        <img src="https://storage.googleapis.com/nero-assets/nero-game-logo/nero-logo-grey.png" alt='nero-icon' />
        <p style={{marginTop:20}}>Thank you for your payment. Your transaction has been successfully completed.</p>
      </div>

      <div className="user-detail padding-50 leading-relaxed">
        <h2 className='text-black text-lg md:text-xl'>Informasi Pembelian</h2>
        <p><strong>Username:</strong> {username}</p>
        <p><strong>Phone Number:</strong> {paymentData?.phone_number}</p>
        { paymentData?.game_id ? (<p><strong>Game ID:</strong> {paymentData?.game_id}</p>) : null }
        { paymentData?.package ? (<p><strong>Package:</strong> {paymentData?.package}</p>) : null }
        <p><strong>Transaction ID:</strong> {paymentData?.transaction_id ?? paymentData?.payment_id}</p>
        <p><strong>Amount Paid:</strong> {formatCurrency(paymentData?.amount)}</p>
      </div>

      {/* <div className="payment-detail">
        <h2>Payment Details</h2>
        <p><strong>Payment Method:</strong> {userData.payment_method}</p>
        {paymentDetails.vaNumber && (
          <p><strong>VA Number:</strong> {paymentDetails.vaNumber}</p>
        )}
        {paymentDetails.bankCode && (
          <p><strong>Bank Code:</strong> {paymentDetails.bankCode}</p>
        )}
        {paymentDetails.eWallet && paymentDetails.eWallet.channel && (
          <p><strong>E-Wallet Channel:</strong> {paymentDetails.eWallet.channel}</p>
        )}
        {paymentDetails.viewName && (
          <p><strong>View Name:</strong> {paymentDetails.viewName}</p>
        )}
      </div> */}

      <div className="back-to-home">
        <Button onClick={handleBackToHome} className="black">
          <CIcon icon={cilHome} /> Back to Home
        </Button>
      </div>
    </div>
  );
}

export default Spage;
