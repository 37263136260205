/**
 * Mencari key dalam `config` yang paling cocok dengan `gameName`.
 * Jika tidak ada kecocokan langsung, mengembalikan `null`.
 *
 * @param {string} gameName - Nama game yang akan dicari.
 * @param {Object} config - Objek konfigurasi produk dengan key sebagai nama game.
 * @returns {string|null} - Mengembalikan key yang cocok atau `null` jika tidak ditemukan.
 */
export const findKeyObjectMatch = (gameName, config) => {
    const matchedKey = Object.keys(config).find(key => 
        gameName.toLowerCase().includes(key.toLowerCase())
    );

    return matchedKey ? config[matchedKey] : null;
};
