import axios from 'axios';

// Set default content type for POST requests
axios.defaults.headers.post['Content-Type'] = 'application/json';

export default class API {
  static getBaseUrl() {
    const hostname = window.location.hostname;
    if (hostname.includes('localhost')) {
      return 'http://localhost:4001/api/';
    } else if (hostname === 'nerogames.id') {
      return 'https://api.nerogaming.id/api/';
    } else {
      return 'https://dev.nerogaming.id/api/';
    }
  }

  static BASE_URL = API.getBaseUrl();

  static async getConfig() {
    const token = localStorage.getItem('token');
    if (token) {
      return {
        headers: {
          'x-access-token': token,
        },
      };
    } else {
      // Handle case where token is not available
      // console.error('Token not found in localStorage');
      return {
        headers: {},
      };
    }
  }

  // Make a POST request
  static async post(endpoint, body, customHeaders = {}) {
    const config = await API.getConfig();

    const url = `${API.BASE_URL}${endpoint}`;
    try {
      const response = await axios.post(url, body, { ...config, ...customHeaders });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  // Make a PUT request
  static async put(endpoint, body, customHeaders = {}) {
    const config = await API.getConfig();

    const url = `${API.BASE_URL}${endpoint}`;
    try {
      const response = await axios.put(url, body, { ...config, ...customHeaders });
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }

  // Make a GET request
  static async get(endpoint, params = '') {
    const config = await API.getConfig();

    const url = `${API.BASE_URL}${endpoint}${params}`;
    try {
      const response = await axios.get(url, config);
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  // Make a DELETE request
  static async delete(endpoint, params = '') {
    const config = await API.getConfig();

    const url = `${API.BASE_URL}${endpoint}${params}`;
    try {
      const response = await axios.delete(url, config);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
}
