import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import API from "../../api";
import { toast } from "react-toastify";
import Skeleton from "react-loading-skeleton";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/style.css";
import productsConfig from "../../data/productConfig";
import NeroLoader from "../../components/loaders/NeroLoader";
import { arrayFund } from "../../data/arrayFund";
import { findKeyObjectMatch } from "../../utils/findMatchkeyObject";
import { ProductForm } from "./ProductForm";

const LoadingSkeleton = () => (
  <div className="product-container shop-area">
    <div className="product-wrapper">
      <div className="header-grid">
        <div className="header-wrapper">
          <div className="header-banner">
            <Skeleton height={200} />
          </div>
          <div className="product-image">
            <Skeleton circle={true} height={150} width={150} />
            <h4>
              <Skeleton width={100} />
            </h4>
          </div>
        </div>
        <div className="product-description">
          <h2>Cara Top Up</h2>
          <Skeleton count={5} />
        </div>
      </div>
      <div className="product-content-wrapper">
        <div className="content-header">
          <h2>Pilih Nominal</h2>
        </div>
        <div className="content-product">
          <Skeleton count={5} height={100} />
        </div>
      </div>
    </div>
  </div>
);

const Error = ({ message }) => <div>{message}</div>;
const GameNotFound = () => <div>Game not found</div>;
const ConfigNotFound = () => <div>Game configuration not found</div>;

const ProductHeader = ({ game }) => (
  <div className="header-grid">
    <div className="header-wrapper">
      <div className="header-banner">
        <div className="banner">
          <img
            style={{ width: "100%" }}
            src="https://storage.googleapis.com/nero-assets/promo-banner/christmas-promo-2024-m.jpg"
            alt="Banner Promo Desember Natal"
          />
        </div>
      </div>
      <div className="product-image">
        <img src={game.image} alt={game.name} />
        <h4 className="text-black text-lg font-semibold">{game.name}</h4>
      </div>
    </div>
    <div className="product-description">
      <h2 className="text-black">Cara Top Up</h2>
      {findKeyObjectMatch(game?.name, productsConfig)?.caraTopUp?.length > 0 ? (
        findKeyObjectMatch(game?.name, productsConfig)?.caraTopUp?.map((step, index) => (
          <p key={index}>
            {index + 1}. {step}
          </p>
        ))
      ) : (
        <p>No steps available for this game.</p>
      )}
    </div>
  </div>
);

const Product = () => {
  const { gameName } = useParams();
  const history = useHistory();
  const [game, setGame] = useState(null);
  const [denominations, setDenominations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDenominations = async () => {
      try {
        const response = await API.get("/retrieveDTU");
        if (response.status === 200) {
          const selectedGame = response.data.response.list_dtu.find(
            (product) => product.name === gameName
          );

          setGame(selectedGame);
          setDenominations(selectedGame.denom);
        } else {
          setError(response.data.message || "Failed to fetch denominations");
        }
      } catch (error) {
        setError("An error occurred while fetching denominations");
      } finally {
        setLoading(false);
      }
    };

    fetchDenominations();
  }, [gameName]);

  const handleSubmit = async (values) => {
    setLoading(true);

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "submit-btn-voucher", // Match this to your GTM trigger name
      clickID: "submit-btn-voucher", // Ensure the Click ID is correct if GTM trigger relies on it
      paymentMethod: values.paymentMethod,
      selectedDenom: values.selectedDenom,
      userID: values.userID,
    });

    // Adjust the game_id for specific games
    if (gameName?.toLowerCase()?.includes("mobile legends") ) {
      values.game_id = "mobilelegend";
    } else if (gameName?.toLowerCase()?.includes("free fire")) {
      values.game_id = "freefire";
    } else {
      // Set the game_id to the game name for other games
      values.game_id = gameName;
    }

    const selectedParent = arrayFund.find(
      (method) =>
        method.child.some((child) => child.code === values.paymentMethod) ||
        method.name === values.paymentMethod
    );
    const parentMethodName = selectedParent
      ? selectedParent.payment_method
      : values.paymentMethod;
    const selectedCode =
      selectedParent && selectedParent.child.length > 0
        ? values.paymentMethod
        : null;
    const selectedDenom = denominations.find(
      (d) => d.package === values.selectedDenom
    );

    console.log(values)

    const submissionData = {
      game_username: values.username, // Use 'name' instead of 'username'
      game_user_id: values.userID,
      game_id: values.game_id,
      amount: selectedDenom.price,
      phone_number: values.phoneNumber,
      payment_method: parentMethodName,
      channel_code: selectedCode ?? "",
      game_server_id: values.zoneID,
      // package: selectedDenom.package,
      ref_id: values.ref_id,// Add ref_id to submission data
    };

    try {
      setLoading(true);
      const response = await API.post("payment-topup", submissionData);
      if (response.status === 201) return history.push({ pathname: `/confirmation/${response.data.payment_request_id}` });

      setLoading(false)
      toast.error(response?.message ?? "An error occurred during payment processing");

    } catch (error) {
      setLoading(false)
      toast.error(error?.message ?? "An error occurred during payment processing");
    }
  };

  if (loading) return (
    <div className="min-h-screen w-full top-up-page shop-area">
      <NeroLoader />
    </div>
  );

  if (error) return <Error message={error} />;
  if (!game) return <GameNotFound />;
  const gameConfig = findKeyObjectMatch(game?.name, productsConfig)
  if (!gameConfig) return <ConfigNotFound />;

  return (
    <div className="product-container shop-area">
      <div className="product-wrapper">
        <ProductHeader game={game} />
        <div className="product-content-wrapper">
          <div className="content-header flex items-center justify-start">
            <div className="number-symbol border-none">1</div>
            <h2 className="text-black text-[27.2px]">Pilih Nominal</h2>
          </div>
          <div className="content-product">
            <ProductForm
              game={game}
              denominations={denominations}
              gameConfig={gameConfig}
              onSubmit={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
