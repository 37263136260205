import { toast } from "react-toastify";

export const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
        toast.success("VA Number copied to clipboard!", {
            position: 'top-right',
        });
    }).catch(err => {
        toast.error("Failed to copy VA Number", {
            position: 'top-right',
        });
    });
};